@import "font.scss";
@import "variables.scss";
@import "node_modules/bootstrap/scss/bootstrap";

.uppy-Dashboard {
  .uppy-Dashboard-inner {
    width: 100% !important;
  }

  .uppy-Dashboard-AddFiles-title {
    margin-bottom: 15px
  }

  .uppy-Dashboard-serviceMsg-text {
    padding: 0 15px 0 50px;
  }

  .uppy-Dashboard-serviceMsg-icon {
    position: absolute;
    top: calc(50% - 10px);
    left: 15px;
    height: 20px;
    width: auto;
  }
}

.logo {
  max-height: 22px;
  height: 100%;
  width: auto;
  max-width: 100%;
}