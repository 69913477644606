@font-face {
  font-family: 'Alergia Normal';
  src: url(../fonts/alergia-normal/AlergiaNormal-UltraLight.woff2) format('woff2'),
  url(../fonts/alergia-normal/AlergiaNormal-UltraLight.woff) format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Alergia Normal';
  src: url(../fonts/alergia-normal/AlergiaNormal-Thinitalic.woff2) format('woff2'),
  url(../fonts/alergia-normal/AlergiaNormal-Thinitalic.woff) format('woff');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Alergia Normal';
  src: url(../fonts/alergia-normal/AlergiaNormal-SemiBolditalic.woff2) format('woff2'),
  url(../fonts/alergia-normal/AlergiaNormal-SemiBolditalic.woff) format('woff');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Alergia Normal';
  src: url(../fonts/alergia-normal/AlergiaNormal-ExtraLightitalic.woff2) format('woff2'),
  url(../fonts/alergia-normal/AlergiaNormal-ExtraLightitalic.woff) format('woff');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Alergia Normal';
  src: url(../fonts/alergia-normal/AlergiaNormal-Light.woff2) format('woff2'),
  url(../fonts/alergia-normal/AlergiaNormal-Light.woff) format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Alergia Normal';
  src: url(../fonts/alergia-normal/AlergiaNormal-Mediumitalic.woff2) format('woff2'),
  url(../fonts/alergia-normal/AlergiaNormal-Mediumitalic.woff) format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Alergia Normal';
  src: url(../fonts/alergia-normal/AlergiaNormal-ExtraLight.woff2) format('woff2'),
  url(../fonts/alergia-normal/AlergiaNormal-ExtraLight.woff) format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Alergia Normal';
  src: url(../fonts/alergia-normal/AlergiaNormal-UltraLightitalic.woff2) format('woff2'),
  url(../fonts/alergia-normal/AlergiaNormal-UltraLightitalic.woff) format('woff');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Alergia Normal';
  src: url(../fonts/alergia-normal/AlergiaNormal-Bolditalic.woff2) format('woff2'),
  url(../fonts/alergia-normal/AlergiaNormal-Bolditalic.woff) format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Alergia Normal';
  src: url(../fonts/alergia-normal/AlergiaNormal-Lightitalic.woff2) format('woff2'),
  url(../fonts/alergia-normal/AlergiaNormal-Lightitalic.woff) format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Alergia Normal';
  src: url(../fonts/alergia-normal/AlergiaNormal-Bold.woff2) format('woff2'),
  url(../fonts/alergia-normal/AlergiaNormal-Bold.woff) format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Alergia Normal';
  src: url(../fonts/alergia-normal/AlergiaNormal-Italic.woff2) format('woff2'),
  url(../fonts/alergia-normal/AlergiaNormal-Italic.woff) format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Alergia Normal';
  src: url(../fonts/alergia-normal/AlergiaNormal-Blackitalic.woff2) format('woff2'),
  url(../fonts/alergia-normal/AlergiaNormal-Blackitalic.woff) format('woff');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Alergia Normal';
  src: url(../fonts/alergia-normal/AlergiaNormal-Medium.woff2) format('woff2'),
  url(../fonts/alergia-normal/AlergiaNormal-Medium.woff) format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Alergia Normal';
  src: url(../fonts/alergia-normal/AlergiaNormal-Hairline.woff2) format('woff2'),
  url(../fonts/alergia-normal/AlergiaNormal-Hairline.woff) format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Alergia Normal';
  src: url(../fonts/alergia-normal/AlergiaNormal-Regular.woff2) format('woff2'),
  url(../fonts/alergia-normal/AlergiaNormal-Regular.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Alergia Normal';
  src: url(../fonts/alergia-normal/AlergiaNormal-Thin.woff2) format('woff2'),
  url(../fonts/alergia-normal/AlergiaNormal-Thin.woff) format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Alergia Normal';
  src: url(../fonts/alergia-normal/AlergiaNormal-Black.woff2) format('woff2'),
  url(../fonts/alergia-normal/AlergiaNormal-Black.woff) format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Alergia Normal';
  src: url(../fonts/alergia-normal/AlergiaNormal-Hairlineitalic.woff2) format('woff2'),
  url(../fonts/alergia-normal/AlergiaNormal-Hairlineitalic.woff) format('woff');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Alergia Normal';
  src: url(../fonts/alergia-normal/AlergiaNormal-SemiBold.woff2) format('woff2'),
  url(../fonts/alergia-normal/AlergiaNormal-SemiBold.woff) format('woff');
  font-weight: 600;
  font-style: normal;
}
